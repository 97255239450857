


.newsPage {
    padding-bottom: 200px;
    max-width: 60vw;
    margin-top: 10vh;
    color: #343434;
    font-family: 'Source Serif 4', serif;
}
.contact {
    transition: color 300ms;
}
.contact:hover {
    color: #8a0935;
}

.contact:active {
    color: #ff0054;
}
@media (max-width: 600px) {
    .newsPage {
        max-width: 95vw;

    }

    .newsTitle {
        font-size: 26px!important;
    }
}
/*.newsText img, .newsText iframe {*/
/*    width: 100%!important;*/
/*}*/



/*@media (max-width: 400px) {*/
/*    iframe, img {*/
/*        max-width: 300px;*/
/*    }*/
/*}*/



.newsData {
    font-family: 'Source Serif 4', serif !important;
    margin: 0!important;
    text-transform: uppercase!important;
    font-size: 20px!important;
    font-weight: 600!important;
    color: #820000!important;
}

.newsPageViews {
    font-size: 18px;
    color: #820000;
}
.carouselImage {
    height: 100% !important;
    object-fit: cover!important;
    width: inherit;
}


.carouselImgWrapper {
    max-width: 800px;
    height: 540px;
    background-color: rgba(44,44,44,0.44);
}

.newsTitle {
    font-family: 'Source Serif 4', serif;
    margin: 10px 0 0 0!important;
    font-size: 36px;
    font-weight: 700;
}

.newsText {
    margin: 1em 0;
    font-size: 17px;
    line-height: 1.52941176em;
}

.whatReadNext {
    margin-top: 60px;
    font-size: 42px;
    text-align: center;
    color: #820000;
}