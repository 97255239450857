
.chatInput {
    padding-right: 3px;
    background-color: var(--forum-primary-bg-color);
    width: 100%;
    align-self: flex-end;
}

.addImg {
    color: white;
    background: none;
    border: dashed rgba(255, 255, 255, 0.47) 1px;
    transition: border 500ms;
    border-radius: 5px;
    width: 80px;
    height: 80px;
}

.sendBtn {
    cursor: pointer;
    background-color: #8D5B4C;
    border: solid 1px rgba(196, 187, 175, 0.36) !important;
    transition: background-color 400ms!important;
}

.sendBtn:hover {
    background-color: #694136 !important;
}

.editBtn {
    cursor: pointer;
    background-color: #008000;
    transition: background-color 400ms!important;
}

.editBtn:hover {
    background-color: #05a100 !important;
}

.replyBtn {
    cursor: pointer;
    background-color: #989a00;
    transition: background-color 400ms!important;
}

.replyBtn:hover {
    background-color: #a3a800 !important;
}

.toolBtn {
    width: 40px!important;
    height: 100%;
    border-radius: 0;
}

.chatSubmitBtn {
    border: none!important;
    font-size: 17px;
    height: 100%;
    border-radius: 0;

}

.addImg:hover {
    cursor: pointer;
    border: dashed rgb(255, 255, 255) 1px;
}

.input {
    position: absolute;
    background-color: #F9F1F3;
    font-size: 18px;
    transition: border-color 200ms;
    border: solid 1px white ;
    resize: none;
    font-family: 'Source Serif 4', serif;
    padding: 5px 10px;
    font-weight: 500;
}

.input:focus {
    -webkit-box-shadow: 5px 6px 29px -19px rgba(3,0,209,1);
    -moz-box-shadow: 5px 6px 29px -19px rgba(3,0,209,1);
    box-shadow: 5px 6px 29px -19px rgba(3,0,209,1);
    outline: none !important;
    border: solid rgba(0, 0, 255, 0.59) 1px;
}
