

.Institution {
    width: fit-content;
}

.InstitutionInfo {
    height: fit-content;
}

.title {
    color: rgb(52, 52, 52);
    transition: color 400ms;
}

.title:hover {
    cursor: pointer;
    color: #2b2bf8;
}