


.documentsPage {
    padding-bottom: 150px;
    max-width: 80vw;
    width: 100%;
    height: 100%;
    min-height: 90vh;
    margin-top: 13vh;
    color: #343434;
    font-family: 'Source Serif 4', serif;
}
.res {
    font-family: 'Source Serif 4', serif;
    font-size: 20px;
}
.searchGroup {
    border-radius: 15px;
    background-color: #fdfdfd;
}

.heading {
}

.input span input::placeholder {
    font-size: 14px;
    color: rgba(52, 52, 52, 0.8);
}
 .groupName {
     width: fit-content;
    text-decoration: underline;
    font-size: 30px;
     color: #343434;
     /*color: #3b0808;*/
    font-weight: 600;
    font-family: 'Source Serif 4', serif;
    margin: 0;
     transition: color 200ms;
}

 .groupName:hover, .subGroupName:hover {
     cursor: pointer;
     color: #930d0d;
 }

 .subGroupName {

    font-size: 21px!important;
    font-family: 'Source Serif 4', serif;
    font-weight: 500;
    cursor: pointer;

 }

 .subGroupName:hover {
 }

.group {

}

.subGroup h3 {

}
