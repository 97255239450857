

.contact {
    border-radius: 15px;
    text-decoration: none!important;
    background-color: #EEEEEE;
    flex-direction: row;
}

.contactName, .contactInfo, .mail, .phone {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Source Serif 4', serif;
}

.contactName h2 {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: initial;
}

.contactName {
    padding: 0 5px;
}


.contactNameWrapper {
    padding: 5px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #CCCCCC;
}

.contactInfo {
    padding: 5px;
}


@media (max-width: 1100px) {
    .contactNameWrapper {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 0!important;
        width: 100%;
    }
}
