

.greetingWrapper {
    background-color: #fff ;
    padding-bottom: 70px;
    padding-top: 50px;
}


.MainPageNewsList {
    max-width: 80vw;
    gap: 20px!important;
    margin: 0;
}

.whiteNewsCard img {
    max-height: 240px;
}

.whiteNewsCard {
    width: 100%;
    /*max-width: 350px;*/
    max-height: 350px!important;
}

.wideNewsCard {
    width: 100%;
    /*max-width: 670px!important;*/
    max-height: 340px!important;
}

.NewsWrapper {
    max-width: 99vw;
    padding: 20px 0;
    width: 100%;
}



.NewsAndBanners {
    width: 100%;
    padding: 10px 10vw;
}

.gridNewsPageList {
    display: grid;
    gap: 5px;
    grid-template-columns: 2fr 1fr 1fr;
}
@media (max-width: 1280px)  {
    .gridNewsPageList {
        grid-template-columns: 1fr 1fr;
    }
}

.NewsListWrapperWithTitle {
    width: 100vw;
    background-color: #f4f4f4;
    padding-top: 30px;
    /*flex-grow: 1;*/

}

@media (max-width: 688px)  {
    .NewsAndBanners {
        display: grid;
    }

    .NewsListWrapperWithTitle {
        order: 2;
    }

    .gridNewsPageList {
        /*grid-template-columns: 1fr;*/
    }

}

@media (max-width: 500px)  {

    .whiteNewsCard , .wideNewsCard{
        /*margin: 0 10px;*/
        width: 100%;
    }
    .whiteNewsCard img {
        width: 100% !important;
    }

    .greetingText {
        padding: 0 10px;
        font-size: 30px!important;
        max-width: initial!important;
        text-align: start!important;
    }

}

.greetingTitle {
    user-select: none;
    font-weight: 500;
    font-family: 'Source Serif 4', serif;
    font-size: 40px;
}

.greetingText {
    user-select: none;
    transition: font-size 200ms;
    font-family: 'Source Serif 4', serif;
    max-width: 70vw;
    font-size: 40px;
    font-weight:300;
    font-style: normal;
    text-align: center;
}

.content {
    padding-bottom: 50px;
    color: black;
}
