

.Wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--forum-primary-bg-color);
}
.postListPlaceholder {
    padding-top: 5vh;
}
.postListPlaceholder p {
    text-align: center;
    color: white;
    font-size: 18px;
}

.newPostBtn {
    margin: 5px;
    height: 100px;
    max-width: 80px;
}

.postList {
    color: #263465;
    column-gap: 10px;
    row-gap: 20px;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}