

.footerTitle {
    cursor: pointer;
    max-width: 500px;
    letter-spacing: 5px;
    font-weight: 300;
    color: #FFF;
    background: -webkit-linear-gradient(white, #cde0f6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
}

.footerWrapper {
    user-select: none;
    background-color: #232235FF;
    padding: 30px 0;
}

.footerBtn {
    text-decoration: underline;
    color: #b2b2c4;
    font-size: 18px;
    cursor: pointer;
    transition: color 200ms;
}

.footerBtn:hover {
    color: #d8d8e8;

}

@media (max-width: 600px) {
    .footerTitle {
        width: fit-content;
        font-size: 20px;
    }

    .footerBody {
        margin: 0!important;
        padding: 15px;
    }

    .footerWrapper {
        padding: 0;
    }
}

.footerBody {
    margin-left: 10vw;
    font-size: 10px;
    font-family: 'Source Serif 4', serif;
}

.copyright {
    font-weight: 300;
    color: #FFF;
    background: -webkit-linear-gradient(#e2e2e8, #b2b2c4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
}