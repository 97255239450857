

@media (max-width: 600px) {

    .chat {
        width: 100vw !important;
    }

    .chatWindow {
        height: 93vh!important;
        width: 100%;
    }
}

.chatWindow {
    height: 100vh;
    overflow-y: hidden;
}


@media (min-width: 600px) {
    .chatWindow {
        width: 100% !important;
        max-width: inherit;
    }

}
.selectionToolbarWrapper {
    width: 100%;
    position: absolute;
    bottom: 20px;
}
.selectionToolbar {
    width: 100%;
    max-width: 700px;
    padding: 8px 20px;
    background-color: var(--forum-primary-bg-color);
    border-radius: 20px;
}

.chatBottomWrapper {
    background-color: var(--chat-bg-color);
}
.chat {
    width: 100%;
    position: relative;
    background-color: var(--chat-bg-color);
    /*background-image: url("https://wallpapercave.com/wp/wp9875549.jpg");*/
    /*background-image: url("https://i.pinimg.com/736x/67/37/ec/6737ecdb57c1381079cede4a35b1a5d4.jpg");*/
    /*background-image: url("https://wallpapers.com/images/hd/whatsapp-chat-background-fb34cc4b2hg9lmix.jpg");*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
}

/*.myNode {*/

/*}*/

