

.ForumNavbarWrapper {
    width: 200px;
    padding-top: 70px;
    padding-right: 10px;
    order: 1;
}

.maxWidth200 {
    max-width: 200px;
}

@media (max-width: 600px) {
    .ForumNavbarWrapper {
        order: 3;
    }
}
