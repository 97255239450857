

.AddNewsPage {
    padding-bottom: 100px;
    max-width: 80vw;
    min-height: 90vh;
    width: 100%;
    margin-top: 50px;
    color: #343434;
    font-family: 'Source Serif 4', serif;
}

.imagesWrapper {
    max-width: 1100px;
    border: solid #dcd7d7 1px;
    padding: 5px;
}

/*.images {*/
/*    display: grid;*/
/*    grid-template-columns: repeat(8, 1fr);*/
/*}*/

.inputTitle {
    font-size: 16px;
    font-weight: bold;
}


.primaryBg {
    padding: 10px;
    border-radius: 10px;
    background-color: white;
}

.imagePlaceholder {
    max-width: 240px;
    max-height: 240px;
    width: 100%;
}


@media (max-width: 600px) {
    .imagePlaceholder {
        width: 100%;
    }
}



