
.topicPlaceholderWrapper {
    padding-top: 5vh;
    width: 100%;
    height: 100%;
    background-color: var(--forum-primary-bg-color);
    text-align: center;
}
.topicPlaceholderWrapper p {
    color: white;
    font-size: 18px;
}

.topicName {
    color: var(--forum-secondary-title-color);
    font-size: 18px;
    font-weight: 500;
    margin-left: 10px;
}
.topicName:hover {
    user-select: none;
}
.chatName {
    font-size: 16px;
    color: #B1B8BEFF;
    cursor: pointer;
    text-decoration: underline;
}

.topicWrapper {
    user-select: none;
    height: 100%;
    width: 100%;
    background-color: var(--forum-primary-bg-color);
}

.chatDesc {
    font-size: 14px;
    color: #B1B8BEFF;
}

.messagesAmount {
    margin-left: 10px;
    color: rgba(177, 184, 190, 0.62);
    font-size: 14px;
}

.chatWrapper {
    padding-right: 20px!important;
    padding-left: 20px!important;
    cursor: pointer;
    transition: background-color 300ms;
}

.chatWrapper:hover {
    background-color: rgba(255, 255, 255, 0.03);
}

.chatWrapper:active {
    background-color: rgba(255, 255, 255, 0.17);
}
