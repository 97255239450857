

.user {
    width: 100%;
    background-color: rgba(94, 94, 107, 0.46);
}

.name {
    user-select: none;
    font-size: 18px;
    font-weight: 400;
    color: white;
    font-family: 'Open Sans', sans-serif;
}

.about {
    user-select: none;
    font-size: 18px;
    font-weight: 400;
    color: white;
    font-family: 'Open Sans', sans-serif;
}

.date {
    color: white;
    user-select: none;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 600;
}
