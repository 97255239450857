

.messageMainWrapper {
    width: 100%;
    padding-left: 5px;
}
.messageWrapper2 {
    position: relative;
    max-width: 800px;
    width: 100%;
}

.isHighlighted {
    background-color: rgba(33, 27, 26, 0.34);
    transition: background-color 400ms;
}

.stopHighlight {
    transition: background-color 400ms;
    background-color: rgba(0, 0, 0, 0)!important;
}

.repliedMessage {
    cursor: pointer;
    background-color: #00000030;
    border-left: #282A3E solid;
    padding: 5px;
    width: 100%;
    transition: background-color 300ms;
}

.repliedMessage:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 600px) {
    .message {
        max-width: 82vw;
    }
}

.dropDownOptionIcon {
    font-size: 20px!important;
}


.dropDownOptionTitle {
    font-size: 17px;
}

.messageCheckBox {
    scale: 1.5;
}
.selectable {
    transition: background-color 330ms;
}

.selected {
    background-color: rgba(21, 20, 20, 0.49);
    transition: background-color 330ms;
}
.selectable:hover {
    background-color: rgba(21, 20, 20, 0.11);
}
.message {
    margin: 2px 0;
    position: relative;
    max-width: 600px;
    transition: display 1s;
    width: fit-content;
    padding: 0 10px 10px 10px;
    color: #FFF;
    /*color: rgba(255, 255, 255, 0.45);*/
    border-radius: 4px;
    /*margin: 5px 3em 5px 0;*/
    font-family: 'Libre Baskerville', serif;
}

.messageImg {
    border-radius: 4px;
    min-width: 35px;
}

.messageText {
    font-size: 18px;
    white-space: pre-wrap;
    color: #FFF;
    font-weight: 400;
    margin-top: 3px;
    font-family: 'Source Serif 4', serif;
}

.messageDate {
    align-self: flex-start;
    position: relative;
    /*top: -2px;*/
    color: rgba(189, 189, 189, 0.94);
    font-size: 12px;
    font-weight: 400;
    font-family: serif;
}


.senderName {
    font-size: 15px;
    font-weight: 300;
}
