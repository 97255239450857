
.voteWrapper {
    position: relative;
    color: white;
    padding: 2px;
    min-height: 350px;
}

.voteText {
    font-size: 20px;
}

.options {

}

.title {
    user-select: none;
    display: flex;
    height: 40px;
    align-items: center;
    color: #98123F;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
}