

.document {

    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 400ms;
}

.document:hover {

    background-color: #cde0f6;
}
.downloadBtn {
    color: #820000;
    transition: color 200ms;
}
.downloadBtn:hover {
    color: #c20d0d;
}

.downloadBtn:active {
    color: #b95757;
}