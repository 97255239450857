

.loadNewMessagesHandler {
    height: 4px;
    background-color: red;
    position: relative;
    top: 0px;
}


.chatBarItem {
    cursor: pointer;
    background-color: rgba(165, 151, 139, 0.78);
    border: solid rgba(92, 71, 66, 0.15) 3px ;
    padding: 5px 10px;
    border-radius: 3px;
    max-width: 300px;
    transition: background-color 130ms;
}

.chatBarItem:hover {
    background-color: rgb(145, 128, 116);
}

.chatBarItem:active {
    background-color: #C1C1D7;
}

.unreadMessagesFloatButtonWrapper {
    position: absolute;
    bottom: 55px;
    right: 10px;
    transition: all 2s linear;
}



.stickyBadge {
    top: 15px;
    position: -webkit-sticky;
    position: sticky;
}

.unreadMessagesFloatButton {
    color: var(--chat-float-button-border-color);
    border: solid 3px rgba(196, 187, 175, 0.23);
    background-color: var(--chat-float-button-bg-color);
    border-radius: 30px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 1000ms, border-color 200ms;
}


.unreadMessagesFloatButton:hover {
    border: solid 3px rgba(196, 187, 175, 0.6);

}


.chatWrapper {
    width: 100%;
}
@media (min-width: 1700px) {

    .chatWrapper {
        display: flex;
        /*max-width: 90vw;*/
        width: 100%;
    }
}

@media (max-width: 600px) {

    .chatPageWrapper {
        padding-top: 0;
    }

    .chatsWrapper {
        display: none;
    }

    .chatBarItem {
        max-width: inherit;
        width: 100% !important;
    }
}


.chatsWrapper {
    margin-top: 5vh;
    max-width: 300px;
}

.unreadMessagesFloatButton:active {
    background: radial-gradient(circle, #C4BBAF 0%, #8D5B4C 100%);
}


.unreadMessagesFloatButtonWrapper span sup {
    transform: translate(-45%, -66%) !important;
}

.leftContent {
    user-select: none;
    order: 0;
    /*padding-top: 10px;*/
}

@media (max-width: 800px) {
    .leftContent {
        display: none;
    }
}


.changeSizeLine {
    position: relative;
    transition: top 150ms, bottom 150ms, left 150ms, right 150ms;
    user-select: none;
    width: 5px;
    background-color: #0a0a0a;
}

.changeSizeLine:active {
    cursor: grabbing;

}

/*.changeSizeLine:hover .bodyChangeLine {*/
/*    opacity: 1;*/
/*}*/


.grab {
    user-select: none;
    transition: top 150ms, bottom 150ms, left 150ms, right 150ms;
}

.bodyChangeLine {
    z-index: 2147483647;
    transition: opacity 550ms;
    position: absolute;
    width: 20px;
    height: 100vh;
    left: -10px;
    background-color: rgba(43, 47, 63, 0.58);
}
