

.contactsPage {
    margin-left: 100px;
    max-width: 1000px;
    height: 100%;
    min-height: 90vh;
    margin-top: 13vh;
    color: #343434;
    font-family: 'Source Serif 4', serif;
}

.wrapper {
    padding-bottom: 100px;
}

@media (max-width: 900px) {
    .wrapper {
        justify-content: center;
    }
    .contactsPage {
        margin-left: 0;
        max-width: 80vw;
    }
}