.messagesWrapper {
    overflow-x: hidden;
    height: inherit;
    scrollbar-width: thin;          /* "auto" or "thin" */
    scrollbar-color: rgba(108, 108, 108, 0.62) rgba(255, 165, 0, 0);   /* scroll thumb and track */
    padding: 8px 45px;
    align-self: flex-start;
    overflow-y: scroll;
    width: 100%;

}
.chatCreatedOnWrapper {
    background-color: #8D5B4C57;
    position: relative;
    width: fit-content;
    padding: 0 10px 3px 10px;
    color: #FFF;
    border-radius: 11px;
    font-family: 'Libre Baskerville', serif;
}
.chatCreatedOn {
    font-size: 16px;
    white-space: pre-wrap;
    color: #FFF;
    font-weight: 400;
    margin-top: 3px;

}

.messagesWrapper::-webkit-scrollbar-thumb {
    background-color: rgba(108, 108, 108, 0.62);    /* color of the scroll thumb */
}

.messagesWrapper::-webkit-scrollbar-track {
    background: rgba(255, 165, 0, 0);        /* color of the tracking area */
}

.messagesWrapper::-webkit-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
}


@media (max-width: 600px) {
    .messagesWrapper {
        padding: 0;
        max-width: initial;
        max-height: initial;
        height: 100%;
    }
}
