

.wrapper {
    min-width: 160px;
    position: relative;
    border-radius: 15px;
    border: #191a24 1px solid;
    padding: 5px 6px;
    font-family: "Source Serif 4", serif;
    transition: background-color 300ms;
}

.wrapper:hover {
    cursor: pointer;
    background-color: rgba(25, 26, 36, 0.05);
}

.date {
    position: relative;
    bottom: 2px;
    font-size: 14px;
    font-weight: 600;
    color: rgba(165, 42, 42, 0.8);
}

.message {
    font-weight: 500;
    padding: 5px 5px;
    font-size: 16px;
}