
.wrapper {
    min-height: 100vh;
    padding-bottom: 15vh;
    padding-top: 8vh;
}

.segment {
    /*background-color: green!important;*/
}
.forumBg {
    background-size: 40px 40px;
    background-image: radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.15) 1px, transparent 0px);
    background-color: rgb(0, 0, 0);
}

.chat:hover {
    border-radius: 25px;
    background-color: #CDCDDF;
}

.chat:active {
    background-color: #C1C1D7;
}

.pageWrapper {
    max-width: 80vw;
    width: 100%;
}




.chat {
    width: 100%;
    user-select: none;
    transition: background-color 100ms;
    cursor: pointer;
    padding: 5px 10px;
    background-color: #D9D1CA;
    border: solid rgba(92, 71, 66, 0.15) 3px ;
}

.topic {
    width: 100%;
    padding: 5px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-color: #D0CECB;
}



.infoBar {
    order: 3;
}

.ContentList {
    order: 2;
    flex: 1
}

@media (min-width: 1000px) {
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 600px) {

    .infoBar {
        order: 2;
    }

    .pageWrapper {
        margin-top: 70px;
        display: grid!important;
        padding-top: 5vh;
        max-width: 95vw;
    }


    .ContentList {
        order: 0!important;
        max-width: inherit;
        padding: 0;
    }


    .topic {
        width: 100%;
    }

    .chat {
        width: 100%;
    }
}
