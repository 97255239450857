

.documentGroupPage {
    padding-bottom: 150px;
    max-width: 80vw;
    width: 100%;
    height: 100%;
    min-height: 90vh;
    margin-top: 13vh;
    color: #343434;
    font-family: 'Source Serif 4', serif;
}

@media (max-width: 600px) {
    .documentGroupPage {
        margin-top: 8vh;
        padding: 10px;
        max-width: 100vw;

    }
}