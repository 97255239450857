@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;1,300&family=Libre+Baskerville&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Source+Serif+4:opsz,wght@8..60,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vollkorn+SC:wght@400;600;700;900&display=swap');
.smoothAppearance-enter {
    opacity: 0;
}
.smoothAppearance-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}
.smoothAppearance-exit {
    opacity: 1;
}
.smoothAppearance-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}


.isHighlighted {
    background-color: rgba(33, 27, 26, 0.34);
    transition: background-color 1100ms;
}

.stopHighlight {
    transition: background-color 1500ms;
    background-color: rgba(0, 0, 0, 0)!important;
}


html {


    scrollbar-width: thin;          /* "auto" or "thin" */
    scrollbar-color: #50516e rgba(255, 165, 0, 0);   /* scroll thumb and track */
}

.imageWithPlaceholder {
    text-align: center;
    line-height: 30px;
    background-image: url("assets/landscape-placeholder-svgrepo-com.svg");
    background-position: center;
    background-size: 80%;
}

@media (max-width: 800px) {
    .redBtn {
        font-size: 24px!important;
        width: 95vw!important;
    }
}

@media (max-width: 300px) {
    .redBtn {
        font-size: 21px!important;
        width: 95vw!important;
    }
}

@media (min-width: 800px) {
    .redBtn {
        width: 100%;
        max-width: 400px;

    }
}

.redBtn {
    border: 0;
    color: white;
    font-size: 28px;
    height: fit-content;
    font-family: 'Source Serif 4', serif;;
}

.forumStyledScrollBar {
    scrollbar-width: thin;
    scrollbar-color: var(--forum-secondary-bg-color) var(--forum-primary-bg-color);
}

:root {
    --forum-primary-bg-color : #191a24;
    /*--forum-primary-bg-color : #191a24;*/
    --forum-primary-title-color : #282A3E;
    --forum-secondary-title-color : #282A3E ;
    --forum-secondary-bg-color : #2d313d;
    --forum-primary-text-color : #B1B8BEFF;

    --chat-header-bg-color : #191a24;
    --chat-bg-color : #5C4742;

    --message-bg-color : #8D5B4C;

    --chat-float-button-bg-color : var(--message-bg-color);
    --chat-float-button-arrow-color : var(--chat-bg-color);
    --chat-float-button-border-color : var(--forum-primary-bg-color);

    --chat-item-bg-color: var(--chat-header-bg-color);
}

.nonSelect {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html, body {
    height: 100%;
    overflow-x: hidden;
    font-family: 'Roboto', serif;
    box-sizing: border-box;
    padding: 0;
    margin:  0;
}


.IContainer {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.img-fluid {
    width: auto;
    max-width: 100%;
    max-height: 700px;
    height: auto;
}


