

.pageWrapper {
    max-width: 80vw;
    width: 100%;
}

.title {
    color: white;
    user-select: none;
    font-size: 28px;
    font-family: "Source Serif 4", serif;
}
@media (min-width: 600px) {
    .pageWrapper {
        padding-top: 15vh;
    }
}

.usersWrapper {
    flex-grow: 1;
}

.back:hover {
    color: #d00303;
}
@media (max-width: 900px) {

    .usersWrapper {
        order: 1;
    }

}

@media (max-width: 600px) {

    .pageWrapper {
        margin-top: 70px;
        display: grid !important;
        padding-top: 5vh;
        max-width: 95vw;
    }
}
