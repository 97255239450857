

.btnText {
    font-size: 22px;
    color: white;
    font-family: "Vollkorn SC", serif;
    font-weight: 600;
    font-style: normal;
}

.menuItems {
    top: 7px;
    padding-top: 10px !important;
    background-color: rgba(0, 0, 0, 0.84) !important;
    border-radius: 10px!important;
}

.userIcon {
    border-radius: 10px;
    width: 50px;
    height: 50px;
}

.headNavItem {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 7px 10px;
    width: fit-content;
    transition: background-color 200ms,
                font-size 300ms,
                padding-right 300ms,
                padding-left 300ms;
}


.headNavItem:hover {
    cursor: pointer;
    background-color: #232235FF;
}

.headNavItem:active {
    background-color: #161521;
}

.header {
    transition: width 300ms;
    width: 90vw;
}


.navbar {
    height: fit-content;
    padding-bottom: 15px;
    width: 100%;
    background: linear-gradient(0deg,transparent,rgb(35, 34, 53)),linear-gradient(0deg,transparent 20%, rgb(0, 0, 0));
    z-index: 100;
    position: fixed;
}

.title {
    font-family: "Vollkorn SC", serif;
    font-weight: 600;
    font-style: normal;
    transition: letter-spacing 300ms, font-size 300ms;
    cursor: pointer;
    /*letter-spacing: 10px;*/
    /*font-weight: 500;*/
    color: #FFF;
    text-align: center;
    font-size: 25px;
}

@media (max-width: 600px) {
    .btnText {
        font-size: 14px;
    }
}

@media (max-width: 1780px) {
    .title {
       font-size: 22px;
    }
    .btnText {
        font-size: 20px;
    }
}

@media (max-width: 1665px) {
    .title {
        font-size: 21px;
        letter-spacing: 8px;
    }
}


@media (max-width: 1555px) {
    .title {
        letter-spacing: 5px;
    }
}

@media (max-width: 1420px) {
    .header {
        width: 100%;
    }
}

@media (max-width: 1277px) {
    .title {
        letter-spacing: 3px;
    }

    .btnText {
        font-size: 18px;
    }
    .headNavItem {
        padding: 10px 5px;
    }
}


@media (max-width: 1165px) {
    .title {
        letter-spacing: 2px;
    }
}

@media (max-width: 1100px) {
    .title {
        letter-spacing: initial;
    }
}

@media (max-width: 960px) {
    .header {
        gap: 20px;
    }
    .title {
        letter-spacing: initial;
    }
     .headNavItem {
        padding: 5px 5px;
    }
}

@media (max-width: 730px) {
    .header {
        gap: 0px;
    }
}


.logo {
    display: none;
}

.title span {
    max-width: 900px ;
    background: -webkit-linear-gradient(white, #cde0f6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 500px) {
    .header {
        padding: 5px 10px;
    }

    .title {
        line-height: 23px;
        font-size: 20px;
    }
    .mobileNavBtnWrapper {
        display: block!important;
    }

    .headNavItem {
        padding: 10px!important;
        border-radius: 10px;
    }

    .navBtnWrapper {
        display: none;
    }
}

.navBtnWrapper {
    margin-right: 10px;
}

@media (max-width: 1000px) {
    .forumBadge {
        top: -7px;
    }
}
@media (min-width: 800px) {
    .forumBadge {
        top: -1px;
    }
}

.forumBadge {
    font-size: 8px;
}

.menu {
    color:white;
    transition: color 300ms;
}

.menu:active {
    color: #820000;
}

.mobileNavBtnWrapper {
    display: none;
}
