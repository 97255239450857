

.file {
    border-radius: 5px;
    padding: 4px 3px;
}

.file:hover {
    background-color: rgba(0, 0, 0, 0.18);
}

.fileList {
    padding: 8px 7px;
    background-color: rgba(0, 0, 0, 0.09);
    border: solid rgba(0, 0, 0, 0.49) 1px;
    border-radius: 20px;
}

.fileName {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 16;
    color: white;
}

@media (max-width: 600px) {
    .fileName {
        font-size: 12px!important;
        max-width: 100px!important;

    }

    .fileSize {
        font-size: 12px!important;
    }

    .fileList {
        padding: 8px 2px!important;
    }
}

.fileSize {
    white-space: nowrap;
    font-weight: 600;
    color: white;
}

.download:hover {
    cursor: pointer;
}